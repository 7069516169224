@import '../../../styles/_variables.less';

.error-boundary-container {
  max-width: 100%;
  margin: 100px auto;
  padding: 0px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  img {
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
  }
  .title {
    margin-top: 60px;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
  }
  .subtitle {
    margin-bottom: 60px;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    a {
      color: @color;
    }
  }
}
