/*
 Antd menu item expects `a` element as a label to make the whole item area clickable.
 This css makes the report button behave like `a` element, making the whole item area clickable regardless of the button width.
 */
.ant-dropdown .ant-dropdown-menu-item .report-button {
    position: initial;
    max-width: 100%;
}

.ant-dropdown .ant-dropdown-menu-item .report-button:before {
    width: 0;
}

.ant-dropdown .ant-dropdown-menu-item .report-button:after {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    content: " ";
}
