.home-view {
  height: 85vh;
  align-items: center;
  display: flex;
  justify-content: center;
}

.home-view h1 {
  text-align: center;
  font-size: 3em;
}
