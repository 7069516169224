.new-info-image {
  min-width: 26px;
  min-height: 26px;
  width: 26px;
  height: 26px;
  margin-top: -18px;
  margin-left: -6px;
}
.new-info-icon {
  margin-left: 32px;
  svg {
    min-width: 1em;
    min-height: 1em;
    color: #677abf;
  }

  @media (max-width: 767px) {
    margin-left: 16px;
  }
}

.new-info {
  max-width: 350px !important;
  --antd-arrow-background-color: #fff !important;

  .ant-tooltip-inner {
    background-color: #fff !important;
    color: #000 !important;
    padding: 16px !important;
  }

  h2 {
    font-size: 1.8rem;
    font-weight: bold;
    margin: 8px 0;
  }
  h3 {
    font-size: 1.5rem;
    font-weight: bold;
    margin: 8px 0;
  }

  p {
    margin-bottom: 8px;
  }

  ul {
    padding-left: 16px;

    li {
      list-style-type: disc;
      padding-bottom: 4px;
    }
  }
}
