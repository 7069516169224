// Media Queries

.media-xs( @content ) {
  @media only screen and (max-width: @bp-xs) {
    @content();
  }
}

.media-sm( @content ) {
  @media only screen and (min-width: @bp-sm) {
    @content();
  }
}

.media-md( @content ) {
  @media only screen and (min-width: @bp-md) {
    @content();
  }
}

.media-lg( @content ) {
  @media only screen and (min-width: @bp-lg) {
    @content();
  }
}

.on-hover( @content ) {
  &:hover,
  &:focus,
  &:active {
    @content();
  }
}
