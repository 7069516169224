.table-vertical-view {
  colgroup:last-child {
    width: 100%;
  }

  .ant-table-expand-vertical {
    position: sticky !important;
    left: 0;
    z-index: 2;
    background-color: white !important;
    width: 36px;
    max-width: 36px;
    cursor: pointer;

    svg {
      width: 20px;
      height: 20px;
      margin: auto;
    }
  }
  .table-vertical-view-icon-col {
    width: 36px;
    max-width: 36px;
  }

  .ant-table-expand-icon-col {
    width: 30px !important;
  }
  .ant-table-row-expand-icon-cell {
    border-inline-end: 0 !important;
    padding-left: 16px !important;
  }

  .ant-descriptions-item-label,
  .ant-descriptions-item-content {
    padding: 8px !important;
  }
}

@media only screen and (max-width: 576px) {
  .table-vertical-view {
    .ant-table-expand-vertical {
      width: 24px;
      max-width: 24px;
      padding: 4px !important;

      svg {
        width: 16px;
        height: 16px;
      }
    }
    .table-vertical-view-icon-col {
      width: 24px;
      max-width: 24px;
    }
    .ant-table-row:hover .ant-table-cell {
      font-weight: normal !important;
    }
  }
}

.btn-toggle-columns-active {
  color: #4096ff !important;
  border-color: #4096ff !important;
  background: #ffffff !important;
}
