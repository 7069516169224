.ant-table {
  .ant-table-container {
    .ant-table-content {
      table {
        .ant-table-thead {
          .ant-table-row-expand-icon-cell {
            border-right: 0;
          }
        }
        .ant-table-tbody {
          .projections-table {
            .ant-table {
              margin: 0px !important;

              .ant-table-content {
                border-radius: 0 !important;
              }
            }
          }
          .ant-table-expanded-row {
            .ant-table-cell {
              .ant-table {
                margin-right: 4px;
                margin-left: 4px;

                .ant-table-container {
                  .ant-table-content {
                    border: 1px solid #474747 !important;
                    border-radius: 2px !important;
                  }
                }
              }
            }
          }
          .ant-table-row-expand-icon-cell {
            border-right: 0;
            padding-right: 0 !important;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 767px) {
    margin: 0px -20px;

    .ant-table-container {
      .ant-table-content {
        border-left: 0 !important;
        border-right: 0 !important;
        border-radius: 0 !important;

        table {
          .ant-table-thead {
            .ant-table-cell {
              height: 32px;
              padding: 0 4px 0 6px;
            }
            .ant-table-row-expand-icon-cell {
              padding-left: 0;
            }
          }

          .ant-table-tbody {
            .ant-table-row,
            .ant-table-expanded-row {
              .ant-table-cell {
                vertical-align: baseline;
                font-size: 14px;
                padding: 4px 6px;
                height: 32px;

                .text-xs {
                  font-size: 10px;
                }

                .ant-tag {
                  padding: 0;
                  font-size: 11px;
                }
              }
            }
            .ant-table-row {
              &:hover {
                .ant-table-cell {
                  font-weight: 500;
                }
              }
            }
            .ant-table-row-expand-icon-cell {
              .ant-table-row-expand-icon {
                width: 1px;
                border: 0;

                &:before {
                  content: ' ';
                  display: inline-block;
                  color: #ffffff;
                  border-bottom: 1px solid #94a3b8;
                  border-right: 1px solid #94a3b8;
                  height: 4px;
                  width: 4px;
                  transform: rotate(45deg);
                  margin-left: -3px;
                }

                &:after {
                  visibility: hidden;
                }
              }
            }
          }
        }
      }
    }
  }
}
