@import '_variables.less';
@import '_mixins.less';
/*@import '~antd/dist/antd.less'; */
@import 'theme.less';

/** Application Page optimizations */
html {
  font-size: 62.5%; /** makes 1rem = 10px*/
}

body {
  font-size: 1.6rem;
  margin: 0;
  padding: 0;
}

p,
blockquote {
  word-break: break-word;
}

code {
  font-size: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 2px;
}

.application-view {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  .h1,
  .h2,
  .h3,
  .h4,
  .h5,
  .h6 {
    font-weight: 300;
    letter-spacing: 0.02em;
  }
}

h4 {
  font-size: 1.7rem;
}

.text-warning {
  color: #f19f3a;
}
.text-success {
  color: #67c8bb;
}
.text-danger {
  color: #e65250;
}

.text-neutral {
  color: gray;
}

.full-width {
  margin-left: -1rem;
  margin-right: -1rem;

  .media-sm({
    /* hack for full width */
    margin-left: -5rem;
    margin-right: -5rem;
    padding-left: 5rem;
    padding-right: 5rem;
  });
}

/* ANTD OVERWRITES */
.top-bar {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
  padding: 0 2rem;

  @media (max-width: 576px) {
    & {
      padding: 0 1rem;
    }
  }
  button {
    background: #460b38;
    border: none;
  }
  button:hover {
    background: #460b38;
  }
  button:active {
    background: #460b38;
  }
  button:focus {
    background: #460b38;
  }
  ul {
    background: #e6eafd !important;
    li:hover {
      background: #460b38 !important;
      a {
        color: #e6eafd !important;
      }
      div {
        color: #e6eafd !important;
      }
      span {
        color: #e6eafd !important;
      }
      button {
        color: #e6eafd !important;
      }
      .ant-progress-circle-trail {
        stroke: #e6eafd !important;
      }
      .ant-progress-text {
        color: #e6eafd !important;
      }
    }
    a {
      color: #460b38 !important;
    }
    div {
      color: #460b38 !important;
    }
    span {
      color: #460b38 !important;
    }
    button {
      color: #460b38 !important;
    }
    .ant-progress-circle-trail {
      stroke: #e99ba9 !important;
    }
    .ant-progress-text {
      color: #460b38 !important;
    }
  }
  .ant-menu-item-selected {
    background-color: #460b38 !important;
    a {
      color: #e6eafd !important;
    }
  }
}
.ant-layout-header {
  background: #e6eafd;
}

.content-wrapper {
  background: #fff;
  margin-top: 6.4rem;
  padding: 0 1rem;
  min-height: calc(~'100vh - 64px - 70px');
  @media (min-width: 1200px) {
    & {
      padding: 0 2rem 2.4rem;
    }
  }
  @media (max-width: 1200px) {
    & {
      height: calc(~'100vh - 64px - 70px');
      overflow: auto;
    }
  }
}

.search-bar-style {
  flex: auto;

  display: flex;
  justify-content: flex-end;
  align-items: center;

  .quick-search {
    width: 100%;
  }

  @media (max-width: 600px) {
    .quick-search {
      height: 41px;
    }

    .quick-search-button {
      height: 41px;
    }
  }
}

@media (max-width: 600px) {
  .search-bar-style {
    right: 4px;
  }
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.pull-left {
  float: left;
}

.pull-right {
  float: right;
}

.refBadge {
  border-radius: 0.25em;
  background-color: #f39c12;
  padding: 0.2em 0.6em 0.3em;
  text-align: center;
  color: white;
  width: 45px;
  float: right;
}

.loadingSearch {
  text-align: center;
  padding: 20px;
  background-color: #ffffffd6;
  position: absolute !important;
  z-index: 999;
  height: 1000%;
  width: 95%;
}

.ant-message {
  // FIX this later
  z-index: 1090;
  .ant-message-notice-content {
    max-width: 500px;
  }
}

.ant-table-container {
  margin: 0;
}

.ant-popover-inner-content,
.ant-select-dropdown {
  overflow: auto !important;
  max-height: 45vh !important;
}

/* LEGACY ANTD FORM */
.ant-legacy-form {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum';
}
.ant-legacy-form {
  .ant-legacy-form-item-label {
    display: block;
    margin: 0;
    padding: 0 0 8px;
    line-height: 1.5;
    white-space: normal;
    text-align: left;
  }

  .ant-legacy-form-item-required:before {
    display: inline-block;
    margin-right: 4px;
    color: #f5222d;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: '*';
  }

  .ant-legacy-form-item {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5;
    list-style: none;
    font-feature-settings: 'tnum';
    margin-bottom: 24px;
    vertical-align: top;
  }

  .ant-legacy-form-item-control-wrapper {
    width: 100%;
  }

  .ant-legacy-form-item-children {
    position: relative;
  }

  .ant-select-show-arrow {
    width: 100%;
  }

  .ant-legacy-form-explain {
    color: #f5222d;
  }

  .has-error > span > input {
    border-color: #f5222d;
  }

  .has-error > span > input:hover {
    border-color: #f5222d !important;
  }

  .ant-select-auto-complete {
    width: 100%;
  }
}

.assessment-select-dropdown {
  margin-left: 1rem;
}

.send-assessment-select {
  &.ant-select-focused:not(.ant-select-disabled) .ant-select-selector {
    -webkit-box-shadow: 0 0 0 2px rgba(243, 156, 18, 0.2) !important; // can't avoid
    box-shadow: 0 0 0 2px rgba(243, 156, 18, 0.2) !important; // can't avoid
  }

  .ant-select-selector {
    border-color: rgb(243, 156, 18) !important; // can't avoid
    background-color: #ffffff !important; // can't avoid
    border-radius: 4px !important; // can't avoid
    color: #5d5d5d;
  }

  > .ant-select-arrow {
    top: calc(50% - 3px);
  }
}

.assessment-item {
  .ant-select-item-option-content {
    font-size: 12px;
  }
}

.next-interview-tag-overwrite {
  background-color: #e7e7e7e7;
  font-weight: normal;
  border-radius: 3px;
  padding-top: 0;
  margin-top: 6px;
  border: none;
}

@media only screen and (min-width: 568px) {
  .new-app-version-hire-notification {
    margin-right: 40px;
    margin-bottom: 0;
    position: fixed;
    bottom: 3vh;
    right: 6vh;
  }
}

@media only screen and (max-width: 567px) {
  .new-app-version-hire-notification {
    bottom: 6vh;
  }
}
