.notification-bell {
  padding: 12px;
  cursor: pointer;
  margin-left: 8px;

  svg {
    color: #460b38;
    font-size: 20px;
  }
}

.notification-drawer {
  .ant-drawer-body {
    padding: 1rem 2rem;
    background-color: #f5f5f5;
  }

  .ant-drawer-footer {
    padding: 0;
  }
}

.notification-card {
  .ant-card-head-title {
    font-weight: initial;

    .ant-checkbox-wrapper > span:nth-child(2) {
      padding-left: 16px;
    }
  }

  .ant-card-actions {
    flex-wrap: wrap !important;

    li {
      width: auto !important;
      flex: 1;
      padding: 4px 0;
      margin: 0;
      border-bottom: 1px solid #f0f0f0;
      border-right: 1px solid #f0f0f0;
    }
  }
}

.notifications-bulk-actions {
  background-color: white;

  .notification-card {
    border-radius: 0;
  }
}

.notification-select-all {
  width: 100%;
  padding: 12px 0px 24px;
  position: sticky;
  top: -12px;
  z-index: 10;
  background: #f5f5f5;
}
