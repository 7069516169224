.ant-layout-content .button-filter-wrapper {
  position: absolute;
  width: calc(100vw - 4rem);
  display: none;
}

.ant-layout-content .button-filter-wrapper .filter-button {
  padding: 12px 0;
  position: relative;
  bottom: 58px;
}

.button-filter-wrapper .filter-button .button-position {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.button-filter-wrapper .filter-button .button-position .button-content {
  border: 1px solid #e2e8f0;
  background-color: #ffffff;
  padding: 4px 8px;
  border-radius: 4px;
}

.button-filter-wrapper .filter-button .button-position .button-content:active {
  background-color: #f3f4f6;
}

.button-filter-wrapper .filter-button .button-position .button-content.open {
  border-color: #1f2937;
}

.button-filter-wrapper .filter-button .button-position .button-content .button-text {
  margin: 0 4px;
}

@media only screen and (max-width: 992px) {
  .ant-layout-content .button-filter-wrapper {
    display: block;
  }
}
