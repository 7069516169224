.app-announcements {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex-shrink: 0 !important;

  .ant-popover-content {
    min-width: 400px;
    max-width: 400px !important;
  }

  .app-announcements-button-container {
    position: relative;
    width: fit-content;
    height: fit-content;
    display: flex;
    gap: 4px;
  }

  .app-announcements-button {
    width: fit-content !important;
    border-style: solid !important;
    border-color: transparent !important;
    background-color: transparent !important;
    height: fit-content !important;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    border-radius: 50%;

    img {
      width: 32px;
      height: 35px;
    }

    @media screen and (min-width: 993px) {
      img {
        width: 39px;
        height: 43px;
      }
    }
  }

  .app-announcements-button:focus-visible {
    outline-width: 0;
    outline-color: #f51313;
  }
}

.app-announcements-popover {
  min-width: 280px;
  max-width: 280px;

  h5.ant-typography {
    margin-top: 0;
  }

  .ant-list-empty-text {
    margin-top: 12px;
    margin-bottom: 12px;
    padding: 16px;
  }

  .ant-list.announcement-list {
    max-height: 75vh !important;
  }

  .app-announcements-read-more {
    font-size: 12px;
    width: fit-content;
    cursor: pointer;
    color: #1677ff;
    background-color: transparent;
    border: 0;
    padding: 0;
  }

  .app-announcement-list-item {
    display: flex;
    gap: 0.5rem;
    flex-direction: column;
    justify-content: flex-start !important;
    align-items: flex-start !important;
    border-bottom: 1px solid #f0f0f0;
    max-width: 400px;
  }

  @media screen and (min-width: 536px) {
    max-width: 320px;
  }

  @media screen and (min-width: 768px) {
    max-width: 420px;
  }
}

.app-announcement-prose {
  color: var(--app-announcement-prose-body);
  max-width: 65ch;
}

.app-announcement-clamp-3 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}

.app-announcement-prose :where(p):not(:where([class~='not-prose'], [class~='not-prose'] *)) {
  margin-top: 1.25em;
  margin-bottom: 1.25em;
}
.app-announcement-prose
  :where([class~='lead']):not(:where([class~='not-prose'], [class~='not-prose'] *)) {
  color: var(--app-announcement-prose-lead);
  font-size: 1.25em;
  line-height: 1.6;
  margin-top: 1.2em;
  margin-bottom: 1.2em;
}
.app-announcement-prose :where(a):not(:where([class~='not-prose'], [class~='not-prose'] *)) {
  color: var(--app-announcement-prose-links);
  text-decoration: underline;
  font-weight: 500;
}
.app-announcement-prose :where(strong):not(:where([class~='not-prose'], [class~='not-prose'] *)) {
  color: var(--app-announcement-prose-bold);
  font-weight: 600;
}
.app-announcement-prose :where(a strong):not(:where([class~='not-prose'], [class~='not-prose'] *)) {
  color: inherit;
}
.app-announcement-prose
  :where(blockquote strong):not(:where([class~='not-prose'], [class~='not-prose'] *)) {
  color: inherit;
}
.app-announcement-prose
  :where(thead th strong):not(:where([class~='not-prose'], [class~='not-prose'] *)) {
  color: inherit;
}
.app-announcement-prose :where(ol):not(:where([class~='not-prose'], [class~='not-prose'] *)) {
  list-style-type: decimal;
  margin-top: 1.25em;
  margin-bottom: 1.25em;
  padding-inline-start: 1.625em;
}
.app-announcement-prose
  :where(ol[type='A']):not(:where([class~='not-prose'], [class~='not-prose'] *)) {
  list-style-type: upper-alpha;
}
.app-announcement-prose
  :where(ol[type='a']):not(:where([class~='not-prose'], [class~='not-prose'] *)) {
  list-style-type: lower-alpha;
}
.app-announcement-prose
  :where(ol[type='A' s]):not(:where([class~='not-prose'], [class~='not-prose'] *)) {
  list-style-type: upper-alpha;
}
.app-announcement-prose
  :where(ol[type='a' s]):not(:where([class~='not-prose'], [class~='not-prose'] *)) {
  list-style-type: lower-alpha;
}
.app-announcement-prose
  :where(ol[type='I']):not(:where([class~='not-prose'], [class~='not-prose'] *)) {
  list-style-type: upper-roman;
}
.app-announcement-prose
  :where(ol[type='i']):not(:where([class~='not-prose'], [class~='not-prose'] *)) {
  list-style-type: lower-roman;
}
.app-announcement-prose
  :where(ol[type='I' s]):not(:where([class~='not-prose'], [class~='not-prose'] *)) {
  list-style-type: upper-roman;
}
.app-announcement-prose
  :where(ol[type='i' s]):not(:where([class~='not-prose'], [class~='not-prose'] *)) {
  list-style-type: lower-roman;
}
.app-announcement-prose
  :where(ol[type='1']):not(:where([class~='not-prose'], [class~='not-prose'] *)) {
  list-style-type: decimal;
}
.app-announcement-prose :where(ul):not(:where([class~='not-prose'], [class~='not-prose'] *)) {
  list-style-type: disc;
  margin-top: 1.25em;
  margin-bottom: 1.25em;
  padding-inline-start: 1.625em;
}
.app-announcement-prose
  :where(ol > li):not(:where([class~='not-prose'], [class~='not-prose'] *))::marker {
  font-weight: 400;
  color: var(--app-announcement-prose-counters);
}
.app-announcement-prose
  :where(ul > li):not(:where([class~='not-prose'], [class~='not-prose'] *))::marker {
  color: var(--app-announcement-prose-bullets);
}
.app-announcement-prose :where(dt):not(:where([class~='not-prose'], [class~='not-prose'] *)) {
  color: var(--app-announcement-prose-headings);
  font-weight: 600;
  margin-top: 1.25em;
}
.app-announcement-prose :where(hr):not(:where([class~='not-prose'], [class~='not-prose'] *)) {
  border-color: var(--app-announcement-prose-hr);
  border-top-width: 1px;
  margin-top: 3em;
  margin-bottom: 3em;
}
.app-announcement-prose
  :where(blockquote):not(:where([class~='not-prose'], [class~='not-prose'] *)) {
  font-weight: 500;
  font-style: italic;
  color: var(--app-announcement-prose-quotes);
  border-inline-start-width: 4px;
  border-inline-start-color: var(--app-announcement-prose-quote-borders);
  quotes: '\201C''\201D''\2018''\2019';
  margin-top: 1.6em;
  margin-bottom: 1.6em;
  padding-inline-start: 1em;
}
.app-announcement-prose
  :where(blockquote p:first-of-type):not(
    :where([class~='not-prose'], [class~='not-prose'] *)
  )::before {
  content: open-quote;
}
.app-announcement-prose
  :where(blockquote p:last-of-type):not(
    :where([class~='not-prose'], [class~='not-prose'] *)
  )::after {
  content: close-quote;
}
.app-announcement-prose :where(h1):not(:where([class~='not-prose'], [class~='not-prose'] *)) {
  color: var(--app-announcement-prose-headings);
  font-weight: 800;
  font-size: 2.25em;
  margin-top: 0;
  margin-bottom: 0.8888889em;
  line-height: 1.1111111;
}
.app-announcement-prose
  :where(h1 strong):not(:where([class~='not-prose'], [class~='not-prose'] *)) {
  font-weight: 900;
  color: inherit;
}
.app-announcement-prose :where(h2):not(:where([class~='not-prose'], [class~='not-prose'] *)) {
  color: var(--app-announcement-prose-headings);
  font-weight: 700;
  font-size: 1.5em;
  margin-top: 2em;
  margin-bottom: 1em;
  line-height: 1.3333333;
}
.app-announcement-prose
  :where(h2 strong):not(:where([class~='not-prose'], [class~='not-prose'] *)) {
  font-weight: 800;
  color: inherit;
}
.app-announcement-prose :where(h3):not(:where([class~='not-prose'], [class~='not-prose'] *)) {
  color: var(--app-announcement-prose-headings);
  font-weight: 600;
  font-size: 1.25em;
  margin-top: 1.6em;
  margin-bottom: 0.6em;
  line-height: 1.6;
}
.app-announcement-prose
  :where(h3 strong):not(:where([class~='not-prose'], [class~='not-prose'] *)) {
  font-weight: 700;
  color: inherit;
}
.app-announcement-prose :where(h4):not(:where([class~='not-prose'], [class~='not-prose'] *)) {
  color: var(--app-announcement-prose-headings);
  font-weight: 600;
  margin-top: 1.5em;
  margin-bottom: 0.5em;
  line-height: 1.5;
}
.app-announcement-prose
  :where(h4 strong):not(:where([class~='not-prose'], [class~='not-prose'] *)) {
  font-weight: 700;
  color: inherit;
}
.app-announcement-prose :where(img):not(:where([class~='not-prose'], [class~='not-prose'] *)) {
  margin-top: 2em;
  margin-bottom: 2em;
}
.app-announcement-prose :where(picture):not(:where([class~='not-prose'], [class~='not-prose'] *)) {
  display: block;
  margin-top: 2em;
  margin-bottom: 2em;
}
.app-announcement-prose :where(video):not(:where([class~='not-prose'], [class~='not-prose'] *)) {
  margin-top: 2em;
  margin-bottom: 2em;
}
.app-announcement-prose :where(kbd):not(:where([class~='not-prose'], [class~='not-prose'] *)) {
  font-weight: 500;
  font-family: inherit;
  color: var(--app-announcement-prose-kbd);
  box-shadow: 0 0 0 1px rgb(var(--app-announcement-prose-kbd-shadows) / 10%),
    0 3px 0 rgb(var(--app-announcement-prose-kbd-shadows) / 10%);
  font-size: 0.875em;
  border-radius: 0.3125rem;
  padding-top: 0.1875em;
  padding-inline-end: 0.375em;
  padding-bottom: 0.1875em;
  padding-inline-start: 0.375em;
}
.app-announcement-prose :where(code):not(:where([class~='not-prose'], [class~='not-prose'] *)) {
  color: var(--app-announcement-prose-code);
  font-weight: 600;
  font-size: 0.875em;
}
.app-announcement-prose
  :where(code):not(:where([class~='not-prose'], [class~='not-prose'] *))::before {
  content: '`';
}
.app-announcement-prose
  :where(code):not(:where([class~='not-prose'], [class~='not-prose'] *))::after {
  content: '`';
}
.app-announcement-prose :where(a code):not(:where([class~='not-prose'], [class~='not-prose'] *)) {
  color: inherit;
}
.app-announcement-prose :where(h1 code):not(:where([class~='not-prose'], [class~='not-prose'] *)) {
  color: inherit;
}
.app-announcement-prose :where(h2 code):not(:where([class~='not-prose'], [class~='not-prose'] *)) {
  color: inherit;
  font-size: 0.875em;
}
.app-announcement-prose :where(h3 code):not(:where([class~='not-prose'], [class~='not-prose'] *)) {
  color: inherit;
  font-size: 0.9em;
}
.app-announcement-prose :where(h4 code):not(:where([class~='not-prose'], [class~='not-prose'] *)) {
  color: inherit;
}
.app-announcement-prose
  :where(blockquote code):not(:where([class~='not-prose'], [class~='not-prose'] *)) {
  color: inherit;
}
.app-announcement-prose
  :where(thead th code):not(:where([class~='not-prose'], [class~='not-prose'] *)) {
  color: inherit;
}
.app-announcement-prose :where(pre):not(:where([class~='not-prose'], [class~='not-prose'] *)) {
  color: var(--app-announcement-prose-pre-code);
  background-color: var(--app-announcement-prose-pre-bg);
  overflow-x: auto;
  font-weight: 400;
  font-size: 0.875em;
  line-height: 1.7142857;
  margin-top: 1.7142857em;
  margin-bottom: 1.7142857em;
  border-radius: 0.375rem;
  padding-top: 0.8571429em;
  padding-inline-end: 1.1428571em;
  padding-bottom: 0.8571429em;
  padding-inline-start: 1.1428571em;
}
.app-announcement-prose :where(pre code):not(:where([class~='not-prose'], [class~='not-prose'] *)) {
  background-color: transparent;
  border-width: 0;
  border-radius: 0;
  padding: 0;
  font-weight: inherit;
  color: inherit;
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
}
.app-announcement-prose
  :where(pre code):not(:where([class~='not-prose'], [class~='not-prose'] *))::before {
  content: none;
}
.app-announcement-prose
  :where(pre code):not(:where([class~='not-prose'], [class~='not-prose'] *))::after {
  content: none;
}
.app-announcement-prose :where(table):not(:where([class~='not-prose'], [class~='not-prose'] *)) {
  width: 100%;
  table-layout: auto;
  margin-top: 2em;
  margin-bottom: 2em;
  font-size: 0.875em;
  line-height: 1.7142857;
}
.app-announcement-prose :where(thead):not(:where([class~='not-prose'], [class~='not-prose'] *)) {
  border-bottom-width: 1px;
  border-bottom-color: var(--app-announcement-prose-th-borders);
}
.app-announcement-prose :where(thead th):not(:where([class~='not-prose'], [class~='not-prose'] *)) {
  color: var(--app-announcement-prose-headings);
  font-weight: 600;
  vertical-align: bottom;
  padding-inline-end: 0.5714286em;
  padding-bottom: 0.5714286em;
  padding-inline-start: 0.5714286em;
}
.app-announcement-prose :where(tbody tr):not(:where([class~='not-prose'], [class~='not-prose'] *)) {
  border-bottom-width: 1px;
  border-bottom-color: var(--app-announcement-prose-td-borders);
}
.app-announcement-prose
  :where(tbody tr:last-child):not(:where([class~='not-prose'], [class~='not-prose'] *)) {
  border-bottom-width: 0;
}
.app-announcement-prose :where(tbody td):not(:where([class~='not-prose'], [class~='not-prose'] *)) {
  vertical-align: baseline;
}
.app-announcement-prose :where(tfoot):not(:where([class~='not-prose'], [class~='not-prose'] *)) {
  border-top-width: 1px;
  border-top-color: var(--app-announcement-prose-th-borders);
}
.app-announcement-prose :where(tfoot td):not(:where([class~='not-prose'], [class~='not-prose'] *)) {
  vertical-align: top;
}
.app-announcement-prose :where(th, td):not(:where([class~='not-prose'], [class~='not-prose'] *)) {
  text-align: start;
}
.app-announcement-prose
  :where(figure > *):not(:where([class~='not-prose'], [class~='not-prose'] *)) {
  margin-top: 0;
  margin-bottom: 0;
}
.app-announcement-prose
  :where(figcaption):not(:where([class~='not-prose'], [class~='not-prose'] *)) {
  color: var(--app-announcement-prose-captions);
  font-size: 0.875em;
  line-height: 1.4285714;
  margin-top: 0.8571429em;
}
.app-announcement-prose {
  --app-announcement-prose-body: #374151;
  --app-announcement-prose-headings: #111827;
  --app-announcement-prose-lead: #4b5563;
  --app-announcement-prose-links: #111827;
  --app-announcement-prose-bold: #111827;
  --app-announcement-prose-counters: #6b7280;
  --app-announcement-prose-bullets: #d1d5db;
  --app-announcement-prose-hr: #e5e7eb;
  --app-announcement-prose-quotes: #111827;
  --app-announcement-prose-quote-borders: #e5e7eb;
  --app-announcement-prose-captions: #6b7280;
  --app-announcement-prose-kbd: #111827;
  --app-announcement-prose-kbd-shadows: 17 24 39;
  --app-announcement-prose-code: #111827;
  --app-announcement-prose-pre-code: #e5e7eb;
  --app-announcement-prose-pre-bg: #1f2937;
  --app-announcement-prose-th-borders: #d1d5db;
  --app-announcement-prose-td-borders: #e5e7eb;
  --app-announcement-prose-invert-body: #d1d5db;
  --app-announcement-prose-invert-headings: #fff;
  --app-announcement-prose-invert-lead: #9ca3af;
  --app-announcement-prose-invert-links: #fff;
  --app-announcement-prose-invert-bold: #fff;
  --app-announcement-prose-invert-counters: #9ca3af;
  --app-announcement-prose-invert-bullets: #4b5563;
  --app-announcement-prose-invert-hr: #374151;
  --app-announcement-prose-invert-quotes: #f3f4f6;
  --app-announcement-prose-invert-quote-borders: #374151;
  --app-announcement-prose-invert-captions: #9ca3af;
  --app-announcement-prose-invert-kbd: #fff;
  --app-announcement-prose-invert-kbd-shadows: 255 255 255;
  --app-announcement-prose-invert-code: #fff;
  --app-announcement-prose-invert-pre-code: #d1d5db;
  --app-announcement-prose-invert-pre-bg: rgb(0 0 0 / 50%);
  --app-announcement-prose-invert-th-borders: #4b5563;
  --app-announcement-prose-invert-td-borders: #374151;
  font-size: 1rem;
  line-height: 1.75;
}
.app-announcement-prose
  :where(picture > img):not(:where([class~='not-prose'], [class~='not-prose'] *)) {
  margin-top: 0;
  margin-bottom: 0;
}
.app-announcement-prose :where(li):not(:where([class~='not-prose'], [class~='not-prose'] *)) {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}
.app-announcement-prose :where(ol > li):not(:where([class~='not-prose'], [class~='not-prose'] *)) {
  padding-inline-start: 0.375em;
}
.app-announcement-prose :where(ul > li):not(:where([class~='not-prose'], [class~='not-prose'] *)) {
  padding-inline-start: 0.375em;
}
.app-announcement-prose
  :where(.app-announcement-prose > ul > li p):not(
    :where([class~='not-prose'], [class~='not-prose'] *)
  ) {
  margin-top: 0.75em;
  margin-bottom: 0.75em;
}
.app-announcement-prose
  :where(.app-announcement-prose > ul > li p):not(
    :where([class~='not-prose'], [class~='not-prose'] *)
  ) {
  margin-top: 0.75em;
  margin-bottom: 0.75em;
}
.app-announcement-prose
  :where(.app-announcement-prose > ul > li > p:first-child):not(
    :where([class~='not-prose'], [class~='not-prose'] *)
  ) {
  margin-top: 1.25em;
}
.app-announcement-prose
  :where(.app-announcement-prose > ul > li > p:first-child):not(
    :where([class~='not-prose'], [class~='not-prose'] *)
  ) {
  margin-top: 1.25em;
}
.app-announcement-prose
  :where(.app-announcement-prose > ul > li > p:last-child):not(
    :where([class~='not-prose'], [class~='not-prose'] *)
  ) {
  margin-bottom: 1.25em;
}
.app-announcement-prose
  :where(.app-announcement-prose > ul > li > p:last-child):not(
    :where([class~='not-prose'], [class~='not-prose'] *)
  ) {
  margin-bottom: 1.25em;
}
.app-announcement-prose
  :where(.app-announcement-prose > ol > li > p:first-child):not(
    :where([class~='not-prose'], [class~='not-prose'] *)
  ) {
  margin-top: 1.25em;
}
.app-announcement-prose
  :where(.app-announcement-prose > ol > li > p:first-child):not(
    :where([class~='not-prose'], [class~='not-prose'] *)
  ) {
  margin-top: 1.25em;
}
.app-announcement-prose
  :where(.app-announcement-prose > ol > li > p:last-child):not(
    :where([class~='not-prose'], [class~='not-prose'] *)
  ) {
  margin-bottom: 1.25em;
}
.app-announcement-prose
  :where(.app-announcement-prose > ol > li > p:last-child):not(
    :where([class~='not-prose'], [class~='not-prose'] *)
  ) {
  margin-bottom: 1.25em;
}
.app-announcement-prose
  :where(ul ul, ul ol, ol ul, ol ol):not(:where([class~='not-prose'], [class~='not-prose'] *)) {
  margin-top: 0.75em;
  margin-bottom: 0.75em;
}
.app-announcement-prose :where(dl):not(:where([class~='not-prose'], [class~='not-prose'] *)) {
  margin-top: 1.25em;
  margin-bottom: 1.25em;
}
.app-announcement-prose :where(dd):not(:where([class~='not-prose'], [class~='not-prose'] *)) {
  margin-top: 0.5em;
  padding-inline-start: 1.625em;
}
.app-announcement-prose :where(hr + *):not(:where([class~='not-prose'], [class~='not-prose'] *)) {
  margin-top: 0;
}
.app-announcement-prose :where(h2 + *):not(:where([class~='not-prose'], [class~='not-prose'] *)) {
  margin-top: 0;
}
.app-announcement-prose :where(h3 + *):not(:where([class~='not-prose'], [class~='not-prose'] *)) {
  margin-top: 0;
}
.app-announcement-prose :where(h4 + *):not(:where([class~='not-prose'], [class~='not-prose'] *)) {
  margin-top: 0;
}
.app-announcement-prose
  :where(thead th:first-child):not(:where([class~='not-prose'], [class~='not-prose'] *)) {
  padding-inline-start: 0;
}
.app-announcement-prose
  :where(thead th:last-child):not(:where([class~='not-prose'], [class~='not-prose'] *)) {
  padding-inline-end: 0;
}
.app-announcement-prose
  :where(tbody td, tfoot td):not(:where([class~='not-prose'], [class~='not-prose'] *)) {
  padding-top: 0.5714286em;
  padding-inline-end: 0.5714286em;
  padding-bottom: 0.5714286em;
  padding-inline-start: 0.5714286em;
}
.app-announcement-prose
  :where(tbody td:first-child, tfoot td:first-child):not(
    :where([class~='not-prose'], [class~='not-prose'] *)
  ) {
  padding-inline-start: 0;
}
.app-announcement-prose
  :where(tbody td:last-child, tfoot td:last-child):not(
    :where([class~='not-prose'], [class~='not-prose'] *)
  ) {
  padding-inline-end: 0;
}
.app-announcement-prose :where(figure):not(:where([class~='not-prose'], [class~='not-prose'] *)) {
  margin-top: 2em;
  margin-bottom: 2em;
}
.app-announcement-prose
  :where(.app-announcement-prose > :first-child):not(
    :where([class~='not-prose'], [class~='not-prose'] *)
  ) {
  margin-top: 0;
}
.app-announcement-prose
  :where(.app-announcement-prose > :first-child):not(
    :where([class~='not-prose'], [class~='not-prose'] *)
  ) {
  margin-top: 0;
}
.app-announcement-prose
  :where(.app-announcement-prose > :last-child):not(
    :where([class~='not-prose'], [class~='not-prose'] *)
  ) {
  margin-bottom: 0;
}
.app-announcement-prose
  :where(.app-announcement-prose > :last-child):not(
    :where([class~='not-prose'], [class~='not-prose'] *)
  ) {
  margin-bottom: 0;
}

.app-announcement-prose-lg {
  font-size: 1.125rem /* 18px */;
  line-height: 1.7777778;
}
.app-announcement-prose-lg :where(p):not(:where([class~='not-prose'], [class~='not-prose'] *)) {
  margin-top: 1.3333333em;
  margin-bottom: 1.3333333em;
}
.app-announcement-prose-lg
  :where([class~='lead']):not(:where([class~='not-prose'], [class~='not-prose'] *)) {
  font-size: 1.2222222em;
  line-height: 1.4545455;
  margin-top: 1.0909091em;
  margin-bottom: 1.0909091em;
}
.app-announcement-prose-lg
  :where(blockquote):not(:where([class~='not-prose'], [class~='not-prose'] *)) {
  margin-top: 1.6666667em;
  margin-bottom: 1.6666667em;
  padding-inline-start: 1em;
}
.app-announcement-prose-lg :where(h1):not(:where([class~='not-prose'], [class~='not-prose'] *)) {
  font-size: 2.6666667em;
  margin-top: 0;
  margin-bottom: 0.8333333em;
  line-height: 1;
}
.app-announcement-prose-lg :where(h2):not(:where([class~='not-prose'], [class~='not-prose'] *)) {
  font-size: 1.6666667em;
  margin-top: 1.8666667em;
  margin-bottom: 1.0666667em;
  line-height: 1.3333333;
}
.app-announcement-prose-lg :where(h3):not(:where([class~='not-prose'], [class~='not-prose'] *)) {
  font-size: 1.3333333em;
  margin-top: 1.6666667em;
  margin-bottom: 0.6666667em;
  line-height: 1.5;
}
.app-announcement-prose-lg :where(h4):not(:where([class~='not-prose'], [class~='not-prose'] *)) {
  margin-top: 1.7777778em;
  margin-bottom: 0.4444444em;
  line-height: 1.5555556;
}
.app-announcement-prose-lg :where(img):not(:where([class~='not-prose'], [class~='not-prose'] *)) {
  margin-top: 1.7777778em;
  margin-bottom: 1.7777778em;
}
.app-announcement-prose-lg
  :where(picture):not(:where([class~='not-prose'], [class~='not-prose'] *)) {
  margin-top: 1.7777778em;
  margin-bottom: 1.7777778em;
}
.app-announcement-prose-lg
  :where(picture > img):not(:where([class~='not-prose'], [class~='not-prose'] *)) {
  margin-top: 0;
  margin-bottom: 0;
}
.app-announcement-prose-lg :where(video):not(:where([class~='not-prose'], [class~='not-prose'] *)) {
  margin-top: 1.7777778em;
  margin-bottom: 1.7777778em;
}
.app-announcement-prose-lg :where(kbd):not(:where([class~='not-prose'], [class~='not-prose'] *)) {
  font-size: 0.8888889em;
  border-radius: 0.3125rem /* 5px */;
  padding-top: 0.2222222em;
  padding-inline-end: 0.4444444em;
  padding-bottom: 0.2222222em;
  padding-inline-start: 0.4444444em;
}
.app-announcement-prose-lg :where(code):not(:where([class~='not-prose'], [class~='not-prose'] *)) {
  font-size: 0.8888889em;
}
.app-announcement-prose-lg
  :where(h2 code):not(:where([class~='not-prose'], [class~='not-prose'] *)) {
  font-size: 0.8666667em;
}
.app-announcement-prose-lg
  :where(h3 code):not(:where([class~='not-prose'], [class~='not-prose'] *)) {
  font-size: 0.875em;
}
.app-announcement-prose-lg :where(pre):not(:where([class~='not-prose'], [class~='not-prose'] *)) {
  font-size: 0.8888889em;
  line-height: 1.75;
  margin-top: 2em;
  margin-bottom: 2em;
  border-radius: 0.375rem /* 6px */;
  padding-top: 1em;
  padding-inline-end: 1.5em;
  padding-bottom: 1em;
  padding-inline-start: 1.5em;
}
.app-announcement-prose-lg :where(ol):not(:where([class~='not-prose'], [class~='not-prose'] *)) {
  margin-top: 1.3333333em;
  margin-bottom: 1.3333333em;
  padding-inline-start: 1.5555556em;
}
.app-announcement-prose-lg :where(ul):not(:where([class~='not-prose'], [class~='not-prose'] *)) {
  margin-top: 1.3333333em;
  margin-bottom: 1.3333333em;
  padding-inline-start: 1.5555556em;
}
.app-announcement-prose-lg :where(li):not(:where([class~='not-prose'], [class~='not-prose'] *)) {
  margin-top: 0.6666667em;
  margin-bottom: 0.6666667em;
}
.app-announcement-prose-lg
  :where(ol > li):not(:where([class~='not-prose'], [class~='not-prose'] *)) {
  padding-inline-start: 0.4444444em;
}
.app-announcement-prose-lg
  :where(ul > li):not(:where([class~='not-prose'], [class~='not-prose'] *)) {
  padding-inline-start: 0.4444444em;
}
.app-announcement-prose-lg
  :where(.app-announcement-prose-lg > ul > li p):not(
    :where([class~='not-prose'], [class~='not-prose'] *)
  ) {
  margin-top: 0.8888889em;
  margin-bottom: 0.8888889em;
}
.app-announcement-prose-lg
  :where(.app-announcement-prose-lg > ul > li p):not(
    :where([class~='not-prose'], [class~='not-prose'] *)
  ) {
  margin-top: 0.8888889em;
  margin-bottom: 0.8888889em;
}
.app-announcement-prose-lg
  :where(.app-announcement-prose-lg > ul > li > p:first-child):not(
    :where([class~='not-prose'], [class~='not-prose'] *)
  ) {
  margin-top: 1.3333333em;
}
.app-announcement-prose-lg
  :where(.app-announcement-prose-lg > ul > li > p:first-child):not(
    :where([class~='not-prose'], [class~='not-prose'] *)
  ) {
  margin-top: 1.3333333em;
}
.app-announcement-prose-lg
  :where(.app-announcement-prose-lg > ul > li > p:last-child):not(
    :where([class~='not-prose'], [class~='not-prose'] *)
  ) {
  margin-bottom: 1.3333333em;
}
.app-announcement-prose-lg
  :where(.app-announcement-prose-lg > ul > li > p:last-child):not(
    :where([class~='not-prose'], [class~='not-prose'] *)
  ) {
  margin-bottom: 1.3333333em;
}
.app-announcement-prose-lg
  :where(.app-announcement-prose-lg > ol > li > p:first-child):not(
    :where([class~='not-prose'], [class~='not-prose'] *)
  ) {
  margin-top: 1.3333333em;
}
.app-announcement-prose-lg
  :where(.app-announcement-prose-lg > ol > li > p:first-child):not(
    :where([class~='not-prose'], [class~='not-prose'] *)
  ) {
  margin-top: 1.3333333em;
}
.app-announcement-prose-lg
  :where(.app-announcement-prose-lg > ol > li > p:last-child):not(
    :where([class~='not-prose'], [class~='not-prose'] *)
  ) {
  margin-bottom: 1.3333333em;
}
.app-announcement-prose-lg
  :where(.app-announcement-prose-lg > ol > li > p:last-child):not(
    :where([class~='not-prose'], [class~='not-prose'] *)
  ) {
  margin-bottom: 1.3333333em;
}
.app-announcement-prose-lg
  :where(ul ul, ul ol, ol ul, ol ol):not(:where([class~='not-prose'], [class~='not-prose'] *)) {
  margin-top: 0.8888889em;
  margin-bottom: 0.8888889em;
}
.app-announcement-prose-lg :where(dl):not(:where([class~='not-prose'], [class~='not-prose'] *)) {
  margin-top: 1.3333333em;
  margin-bottom: 1.3333333em;
}
.app-announcement-prose-lg :where(dt):not(:where([class~='not-prose'], [class~='not-prose'] *)) {
  margin-top: 1.3333333em;
}
.app-announcement-prose-lg :where(dd):not(:where([class~='not-prose'], [class~='not-prose'] *)) {
  margin-top: 0.6666667em;
  padding-inline-start: 1.5555556em;
}
.app-announcement-prose-lg :where(hr):not(:where([class~='not-prose'], [class~='not-prose'] *)) {
  margin-top: 3.1111111em;
  margin-bottom: 3.1111111em;
}
.app-announcement-prose-lg
  :where(hr + *):not(:where([class~='not-prose'], [class~='not-prose'] *)) {
  margin-top: 0;
}
.app-announcement-prose-lg
  :where(h2 + *):not(:where([class~='not-prose'], [class~='not-prose'] *)) {
  margin-top: 0;
}
.app-announcement-prose-lg
  :where(h3 + *):not(:where([class~='not-prose'], [class~='not-prose'] *)) {
  margin-top: 0;
}
.app-announcement-prose-lg
  :where(h4 + *):not(:where([class~='not-prose'], [class~='not-prose'] *)) {
  margin-top: 0;
}
.app-announcement-prose-lg :where(table):not(:where([class~='not-prose'], [class~='not-prose'] *)) {
  font-size: 0.8888889em;
  line-height: 1.5;
}
.app-announcement-prose-lg
  :where(thead th):not(:where([class~='not-prose'], [class~='not-prose'] *)) {
  padding-inline-end: 0.75em;
  padding-bottom: 0.75em;
  padding-inline-start: 0.75em;
}
.app-announcement-prose-lg
  :where(thead th:first-child):not(:where([class~='not-prose'], [class~='not-prose'] *)) {
  padding-inline-start: 0;
}
.app-announcement-prose-lg
  :where(thead th:last-child):not(:where([class~='not-prose'], [class~='not-prose'] *)) {
  padding-inline-end: 0;
}
.app-announcement-prose-lg
  :where(tbody td, tfoot td):not(:where([class~='not-prose'], [class~='not-prose'] *)) {
  padding-top: 0.75em;
  padding-inline-end: 0.75em;
  padding-bottom: 0.75em;
  padding-inline-start: 0.75em;
}
.app-announcement-prose-lg
  :where(tbody td:first-child, tfoot td:first-child):not(
    :where([class~='not-prose'], [class~='not-prose'] *)
  ) {
  padding-inline-start: 0;
}
.app-announcement-prose-lg
  :where(tbody td:last-child, tfoot td:last-child):not(
    :where([class~='not-prose'], [class~='not-prose'] *)
  ) {
  padding-inline-end: 0;
}
.app-announcement-prose-lg
  :where(figure):not(:where([class~='not-prose'], [class~='not-prose'] *)) {
  margin-top: 1.7777778em;
  margin-bottom: 1.7777778em;
}
.app-announcement-prose-lg
  :where(figure > *):not(:where([class~='not-prose'], [class~='not-prose'] *)) {
  margin-top: 0;
  margin-bottom: 0;
}
.app-announcement-prose-lg
  :where(figcaption):not(:where([class~='not-prose'], [class~='not-prose'] *)) {
  font-size: 0.8888889em;
  line-height: 1.5;
  margin-top: 1em;
}
.app-announcement-prose-lg
  :where(.app-announcement-prose-lg > :first-child):not(
    :where([class~='not-prose'], [class~='not-prose'] *)
  ) {
  margin-top: 0;
}
.app-announcement-prose-lg
  :where(.app-announcement-prose-lg > :first-child):not(
    :where([class~='not-prose'], [class~='not-prose'] *)
  ) {
  margin-top: 0;
}
.app-announcement-prose-lg
  :where(.app-announcement-prose-lg > :last-child):not(
    :where([class~='not-prose'], [class~='not-prose'] *)
  ) {
  margin-bottom: 0;
}
.app-announcement-prose-lg
  :where(.app-announcement-prose-lg > :last-child):not(
    :where([class~='not-prose'], [class~='not-prose'] *)
  ) {
  margin-bottom: 0;
}
