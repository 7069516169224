@smartphones: ~'only screen and (max-width: 575px)';
@tablets: ~'only screen and (min-width: 768px) and (max-width: 959px)';

.search-statuses-dropdown {
  width: 100%;
  // display: flex;
}
.search-statuses-dropdown .application-badge {
  display: inline-block;
  width: 0.75em;
  height: 0.75em;
  margin-right: 0.5em;
  border-radius: 1em;
  background: #ccc;
}
.search-statuses-dropdown .application-status-new .application-badge {
  background: #bdc3c7;
}
.search-statuses-dropdown .application-status-evaluation .application-badge {
  background: #bdc3c7;
}
.search-statuses-dropdown .application-status-reviewed .application-badge {
  background: #bdc3c7;
}
.search-statuses-dropdown .application-status-answered .application-badge {
  background: #bdc3c7;
}
.search-statuses-dropdown .application-status-interviewing .application-badge {
  background: #bdc3c7;
}
.search-statuses-dropdown .application-status-screening .application-badge,
.search-statuses-dropdown .application-status-interviewingTeam .application-badge,
.search-statuses-dropdown .application-status-interviewingPreMgmt .application-badge,
.search-statuses-dropdown .application-status-interviewingMgmt .application-badge,
.search-statuses-dropdown .application-status-interviewingPreHagler .application-badge,
.search-statuses-dropdown .application-status-interviewingHagler .application-badge,
.search-statuses-dropdown .application-status-techTest .application-badge,
.search-statuses-dropdown .application-status-futurePotential .application-badge,
.search-statuses-dropdown .application-status-pendingOffer .application-badge {
  background: #ffff00;
}

.search-statuses-dropdown .application-status-holdApp .application-badge,
.search-statuses-dropdown .application-status-holdTest .application-badge,
.search-statuses-dropdown .application-status-holdScreening .application-badge,
.search-statuses-dropdown .application-status-holdInterviewTeam .application-badge,
.search-statuses-dropdown .application-status-holdInterviewMgmt .application-badge,
.search-statuses-dropdown .application-status-holdHighCostOfLiving .application-badge {
  background: #ffff00;
}

.search-statuses-dropdown .application-status-decision .application-badge {
  background: #1abc9c;
}
.search-statuses-dropdown .application-status-rejected .application-badge {
  background: #e74c3c;
}
.search-statuses-dropdown .application-status-unresponsive .application-badge {
  background: #e74c3c;
}
.search-statuses-dropdown .application-status-declined .application-badge {
  background: #e74c3c;
}
.search-statuses-dropdown .application-status-probablyNot .application-badge {
  background: #ffff00;
}
.search-statuses-dropdown .application-status-stayInTouch .application-badge {
  background: #1abc9c;
}
.search-statuses-dropdown .application-status-maybe .application-badge {
  background: #f39c12;
}
.search-statuses-dropdown .application-status-recruitingApproved .application-badge,
.search-statuses-dropdown .application-status-movedOn .application-badge,
.search-statuses-dropdown .application-status-relationship .application-badge {
  background: repeating-linear-gradient(90deg, transparent, transparent 6px, #eee 6px, #eee 12px),
    #1abc9c;
}
.search-statuses-dropdown .application-status-relationshipPassive .application-badge {
  background: repeating-linear-gradient(90deg, transparent, transparent 6px, #eee 6px, #eee 12px),
    #e74c3c;
}
.search-statuses-dropdown .application-status-bench .application-badge {
  background: #1abc9c;
}
.search-statuses-dropdown .application-status-offered .application-badge {
  background: #1abc9c;
}
.search-statuses-dropdown .application-status-hired .application-badge {
  background: #1abc9c;
}
.search-statuses-dropdown .application-status-hiredOnboarded .application-badge {
  background: #1abc9c;
}

.search-statuses-dropdown .application-status-inner-container {
  display: flex;
  align-items: center;
  width: 100%;
}
.search-statuses-dropdown .application-status-inner-container .application-status-key-shortcut {
  flex: 1;
  text-align: right;
}

.search-statuses-dropdown .ant-select-tree-node-content-wrapper-open {
  pointer-events: none;
}

.search-statuses-dropdown .ant-select-tree-switcher {
  pointer-events: none;
  display: none !important;
}

.search-statuses-container {
  display: flex;
}

.search-statuses-dropdown-dimensions {
  width: auto !important;
  // column-count: 3 !important;
  // gap: 0;
  // height: 365px !important;

  // @media only screen and (max-width: 1024px) {
  //   column-count: unset  !important;
  //   gap: unset;
  //   overflow: auto !important;
  // }

  .ant-select-item-group {
    // break-before: column;
    // flex-basis: 100%;
  }

  // > div > div:nth-child(2) > div > div > div {
  .rc-virtual-list-holder-inner {
    // display: block !important;
  }

  .ant-select-item-option-grouped {
    // padding-left: 12px !important;
  }
}

.status-dropdown-one-column {
  // column-count: unset  !important;
  // gap: unset !important;
  // overflow: auto !important;
  // height: auto !important;
}

.statuses-select {
  /*background-color: red;*/
  flex: 1 1 auto;
}

.statuses-group-header label {
  font-size: 16px;
  color: #1890ff;
}
