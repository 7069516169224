.ant-card.report-filters {
  margin-bottom: 15px;
}

@media only screen and (max-width: 992px) {
  .ant-drawer.bottom-search-filter .ant-drawer-header {
    padding: 16px 15px;
  }

  .ant-drawer.bottom-search-filter .ant-drawer-header-title {
    flex-direction: row-reverse;
  }

  .ant-drawer.bottom-search-filter .ant-drawer-header-title .ant-drawer-close {
    margin-right: 0;
  }

  .ant-drawer.bottom-search-filter .ant-drawer-body {
    padding: 15px 15px 25px 15px;
  }
}
