.projections-cell {
    border: 2px solid transparent;
    position: relative;
}
.projections-cell.projected {
    user-select: none;
}

.projections-cell.projected:not(.editing):after {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    content: " ";
    z-index: 1000;
    user-select: none;
}

.projections-cell.selected {
    background: #CCC !important;
}

.projections-cell.active {
    border: 1px solid #000 !important;
}
.projections-cell.editing {
    background: #FFF !important;
}
.projections-cell .input-cell {
    -webkit-user-select: text;
    user-select: text;
}
