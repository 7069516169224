@background-color: #fff;
@secondary-background-color: #f5f5f5;
@encoded-background-color: replace('@{background-color}', '#', '%23');
@header-background-color: #f0f0f0;
@accent-header-background-color: #d9d9d9;
@accent-secondary-background-color: #f0f0f0;

@border-color: #d9d9d9;
@encoded-border-color: replace('@{border-color}', '#', '%23');

@border-radius: 5px;
@primary-border: 1px solid @border-color;

@triangle-width: 4;
@triangle-height: 8;
@triangle-height-half: 4;

.pill {
  display: flex;

  .pill-item {
    position: relative;
  }

  .pill-item-value {
    position: relative;
    min-width: 30px;
    padding: 0 5px;

    background-color: @background-color;

    display: flex;
    justify-content: center;
    align-items: center;

    .ant-typography {
      color: rgba(0, 0, 0, 0.45);
    }

    &.accent {
      background-color: @secondary-background-color;
    }

    &:nth-child(3) {
      background-color: @secondary-background-color;

      &.accent {
        background-color: @accent-secondary-background-color;
      }

      .ant-typography {
        font-size: 11px;
        font-weight: 500;
      }
    }
  }

  .pill-item:not(:last-child) .pill-item-value.progressive::after {
    z-index: 1;
    content: '';
    position: absolute;

    top: 50%;
    left: 100%;
    transform: translateY(-50%);

    width: ~'@{triangle-width}px';
    height: ~'@{triangle-height}px';
    // Triangle pointing right
    background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="@{triangle-width}" height="@{triangle-height}" viewBox="0 0 @{triangle-width} @{triangle-height}"><polygon points="0,0 @{triangle-width},@{triangle-height-half} 0,@{triangle-height}" fill="@{encoded-background-color}"/><path d="M0,0 L@{triangle-width},@{triangle-height-half} M0,@{triangle-height} L@{triangle-width},@{triangle-height-half}" stroke="@{encoded-border-color}" stroke-width="1"/></svg>')
      no-repeat;
  }

  .pill-item .pill-item-header {
    height: 18px;
    background-color: @header-background-color;

    display: flex;
    align-items: center;
    justify-content: center;

    .ant-typography {
      font-size: 11px;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.45);
    }

    &.accent {
      background-color: @accent-header-background-color;
    }
  }

  .pill-item .pill-item-value,
  .pill-item .pill-item-header {
    border-top: @primary-border;
    border-right: @primary-border;
  }

  .pill-item .pill-item-value:last-child {
    border-bottom: @primary-border;
  }

  .pill-item .pill-item-header {
    border-bottom: none;
  }

  .pill-item:first-child .pill-item-value,
  .pill-item:first-child .pill-item-header {
    border-left: @primary-border;
  }

  .pill-item:first-child .pill-item-value:last-child {
    border-bottom-left-radius: @border-radius;
  }
  .pill-item:first-child .pill-item-header {
    border-top-left-radius: @border-radius;
  }
  .pill-item:last-child .pill-item-value:last-child {
    border-bottom-right-radius: @border-radius;
  }
  .pill-item:last-child .pill-item-header {
    border-top-right-radius: @border-radius;
  }
}
