:root {
  --blue-1: #e6f7ff;
  --blue-2: #bae7ff;
  --blue-3: #91d5ff;
  --blue-5: #40a9ff;
  --blue-6: #1890ff;
  --blue-8: #0050b3;
  --gold-6: #faad14;
  --green-6: #52c41a;
  --red-5: #ff4d4f;
  --yellow-4: #fff566;
}

.ant-table-thead > tr > th.nowrap,
.ant-table-tbody > tr > td.nowrap {
  white-space: nowrap;
}

.ant-table-filter-icon {
  font-size: 20px;
  transform: rotate(-90deg);
}

.ant-input-search > .ant-input-suffix > .ant-input-search-button > .anticon-search {
  font-size: 1.6rem;
}

.ant-btn-circle,
.ant-btn-circle-outline {
  font-size: 1.6rem;
}

.ant-timeline-item-content {
  top: -0.6rem;
}

.ant-table-thead > tr > th.ant-table-selection-column,
.ant-table-tbody > tr > td.ant-table-selection-column {
  min-width: 4.5rem;
  width: 4.5rem;
}

.ant-table-placeholder,
.ant-list .ant-list-empty-text {
  padding: 0;
  .ant-empty-image {
    height: 0;
    img {
      display: none;
    }
  }
}
